import GrComponentFactory from "../gr_component_factory";
import { map } from "lodash";
import { getImages } from "../../modules/shared/image_registry";
import ToSentence from "../../modules/to_sentence";
import UserIcon from "../user/user_icon";
import RelativeTime from "../time/relative_time";
import ShapeIcon from "../shape_icon";

export default GrComponentFactory.createClass({
  displayName: "Notification",

  mixins: [ToSentence],

  propTypes: {
    notification: React.PropTypes.object.isRequired,
  },

  userLink(user) {
    return (
      <a href={user.profileUrl} className="u-defaultType" key={user.profileUrl}>
        <b>{user.viewableName}</b>
      </a>
    );
  },

  userLinks(notification) {
    const userLinks = map(notification.actors, (user) => this.userLink(user));
    return notification.moreActors ?
      this.sentenceJoin(userLinks) : this.toSentence(userLinks);
  },

  renderActorIcon(notification) {
    if (!notification.actor) {
      return <ShapeIcon imageUrl={getImages().notificationFromGRIcon || ""}
                   imageAlt="Goodreads Notification" />;
    } else {
      return <UserIcon user={notification.actor} />;
    }
  },

  renderNewNotificationFlag(notification){
    if(!notification.viewed) {
      return <small className="gr-smallAlert u-marginRightTiny">New</small>;
    }
  },

  renderHistoryMessage(notification) {
    if (notification.historyMessage) {
      return <small className="gr-notification__historyMessage">{ notification.historyMessage }</small>;
    }
  },

  render() {
    const notification = this.props.notification;

    if (notification.error) {
      return null;
    }

    return(
      <div className="gr-mediaBox gr-notification">
        <div className="gr-mediaBox__media u-marginRightMedium">
          {this.renderActorIcon(notification)}
        </div>
        <div className="gr-mediaBox__desc u-defaultType">
          { this.userLinks(notification) }
          { !notification.actor || notification.moreActors ? "" : (notification.directlyAddressed ? ", " : " ") }
          { `${notification.body  } `}
          { notification.resource ?
            <a className="gr-hyperlink" href={ notification.resource.url }>
              { notification.resource.text }
            </a> : null
          }
          <div>
            {this.renderNewNotificationFlag(notification)}
            <small>
              <RelativeTime time={notification.createdAt}
                            longFormat={true} />
            </small>
          </div>
          <div>
            {this.renderHistoryMessage(notification)}
          </div>
        </div>
      </div>
    );
  },
});
